<template>
<v-carousel cycle show-arrows-on-hover hide-delimiters light height="100%">
<v-carousel-item v-for="element in carousel" :key="element.id">
<v-row no-gutters>
    <v-col cols="12" lg="4" sm="12" v-for="info in element.infoblock" :key="info.id">
        <v-card class="ma-1 d-flex flex-row">
            <v-card min-width="50%" max-width="50%" flat>
            <v-img class="align-end" aspect-ratio="0.7" :alt="info.alt" :src="require('../../assets/photo/childs/' + info.img)"/>
            </v-card>
            <v-card flat>
                <v-card-text><p><b>{{info.name}}</b></p><p>{{ info.text }}</p></v-card-text>
            </v-card>
        </v-card>
    </v-col>
</v-row>
</v-carousel-item>
</v-carousel>
</template>

<script>
export default {
    data() {
        return {
            carousel: [
                {
                    id: 1,
                    infoblock: [
                        {
                          id: 1,
                          img: "01_Artem_10.jpg",
                          name: "Артем, 2010 г.р.",
                          text: "Добрый, открытый, общительный подросток. У мальчика много друзей. Артём любит играть в игры на компьютере, гулять, увлекается спортом."  
                        },
                        {
                          id: 2,
                          img: "02_Valeriya_12.jpg",
                          name: "Валерия, 2012 г.р.",
                          text: "Добрая, трудолюбивая, отзывчивая, активная девочка. Любит читать стихи, петь песни, слушать музыку."  
                        },
                        {
                          id: 3,
                          img: "03_Andrey_17.jpg",
                          name: "Андрей, 2017 г.р.",
                          text: "Любит рисовать, играть в мяч и настольные игры, конструкторы Лего. Общительный, любопытный, доброжелательный, трудолюбивый, подвижный. Хороший помощник."  
                        },
                    ]
                },
                {
                    id: 2,
                    infoblock: [
                        {
                          id: 1,
                          img: "04_Alexiya_11.jpg",
                          name: "Алексия, 2011 г.р.",
                          text: "Общительная, дружелюбная девочка. Ответственно подходит к учёбе. У Алексии есть подруги, с которыми у девочки хорошие, тёплые отношения. Увлекается Алексия рисованием. Любит заниматься спортом."  
                        },
                        {
                          id: 2,
                          img: "05_Nikita_14.jpg",
                          name: "Никита, 2014 г.р.",
                          text: "Открытый, общительный, весёлый, коммуникабельный мальчик. У Никиты много друзей в школе, среди ребят проявляет лидерские качества. Увлекается машинами. Любит заниматься спортом."  
                        },
                        {
                          id: 3,
                          img: "06_Maxim_11.jpg",
                          name: "Максим, 2011 г.р.",
                          text: "Доброжелательный, любопытный, общительный, мальчик. Любит мастерить поделки и рисовать, играть в подвижные игры."  
                        },
                    ]
                },
                {
                    id: 3,
                    infoblock: [
                        {
                          id: 1,
                          img: "10_Petr_10.jpg",
                          name: "Петр, 2010 г.р.",
                          text: "Энергичный, активный, доброжелательный. По отношению к другим людям – открытый, правдолюбивый. Никогда не отказывает в помощи взрослым и детям, младше его по возрасту. С удовольствием активно участвует во всех мероприятиях."  
                        },
                        {
                          id: 2,
                          img: "11_Olesya_09.jpg",
                          name: "Олеся, 2009 г.р.",
                          text: "Девочка общительная, доброжелательная, честная, открытая, всегда сдерживает данные обещания. Никогда не откажет в помощи взрослым, а также ребятам в группе младше себя по возрасту.  Олеся - творческая личность, с успехом учится в художественной школе."  
                        },
                        {
                          id: 3,
                          img: "08_Maxim_09.jpg",
                          name: "Максим,  2009 г.р.",
                          text: "Активный, доброжелательный, хороший помощник по хозяйству, легко находит контакт с детьми и взрослыми."  
                        },
                    ]
                },
                {
                    id: 4,
                    infoblock: [
                        {
                          id: 1,
                          img: "13_Kirill_09.jpg",
                          name: "Кирилл, 2009 г.р.",
                          text: "Спокойный, добрый, отзывчивый. В общение со взрослыми и сверстниками проявляет уважение. Любит делать поделки, рисовать, увлекается настольными играми."  
                        },
                        {
                          id: 2,
                          img: "14_Yaroslav_09.jpg",
                          name: "Ярослав, 2009 г.р.",
                          text: "Открытый, доброжелательный, спокойный. Увлекается компьютером и компьютерными играми."  
                        },
                        {
                          id: 3,
                          img: "15_Trofim_08.jpg",
                          name: "Трофим, 2008 г.р.",
                          text: "Добрый, любознательный, активный, подвижный, артистичный, трудолюбивый. Любит играть в подвижные игры, рисовать, увлекается спортом."  
                        },
                    ]
                },
                {
                    id: 5,
                    infoblock: [
                        {
                          id: 1,
                          img: "16_Dariya_09.jpg",
                          name: "Дарья, 2009 г.р.",
                          text: "Общительная, отзывчивая, добрая. Умеет отстаивать собственное мнение, аргументировать позицию. Активно участвует в творческих мероприятиях."  
                        },
                        {
                          id: 2,
                          img: "17_Zarina_08.jpg",
                          name: "Зарина, 2008 г.р.",
                          text: "Добрая, отзывчивая, хорошая помощница. Принимает участие во всех  мероприятиях Центра."  
                        },
                        {
                          id: 3,
                          img: "18_Anvar_11.jpg",
                          name: "Анвар, 2011 г.р.",
                          text: "Подвижный, веселый, общительный. Любит играть в футбол и компьютерные игры, отличный помощник."  
                        },
                    ]
                },
            ],
        }
    }
}
</script>